.Dresscode {
    margin-bottom: 40px;
}

.Dresscode__description {
    margin: 20px;
    text-align: center;
}

.Dresscode__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px;
    margin-bottom: 20px;
}

.Dresscode__item {
    margin: 10px;
    width: 25%;
    height: 80px;
    border: 1px solid #e5e6e9;
    border-radius: 12px;
}

.Dresscode__subtitle {
    margin: 20px;
    font-size: 32px;
    text-align: center;
}

.Dresscode__text {
    margin: 20px;
    text-align: center;
}