.Schedule {
    margin-bottom: 40px;
}

.Schedule__item {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.Schedule__item_time {
    margin-right: 20px;
}

@media (max-width: 768px) {
    .Schedule {
        margin: 0 20px 40px;
    }
  }