.Calendar {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.Calendar__item {
    border: 1px solid #e5e6e9;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 290px;
}

.Calendar__item_header {
    text-align: center;
    font-weight: 600;
    letter-spacing: .02em;
    line-height: 21px;
    margin-bottom: 24px;
    font-size: 26px;
}

.Calendar__days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.Calendar__days>* {
    margin-bottom: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Calendar__weeks {
    color: #646770;
    font-weight: 700;
    line-height: 17px;
    margin-bottom: 4px;
}

.Calendar__weeks_holiday {
    color: #d71920;
}

.Calendar__day {
    line-height: 21px;
    position: relative;
}

.Calendar__day_holiday {
    color: #d71920;
    font-weight: 600;
}

.Calendar__day_heart {
    color: white;
    font-weight: 600;
}

.Calendar__heart {
    color: #d71920;
    position: absolute;
    display: flex;
    z-index: -1;
    top: -8px;
}