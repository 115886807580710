.Timer {
    width: 100%;
    height: 15vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    animation: x 1s 1 cubic-bezier(.215, .61, .355, 1) 4s backwards;
}

.Timer__item {
    text-align: center;
    min-width: 55px;
}

.Timer__item_value {
    font-size: 42px;
}

.Timer__item_label {
    font-size: 12px;
}


@keyframes x {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}