.Question {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
}

.Question__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
}

.Question__description {
    font-size: 12px;
    color: gray;
}

.Question__item {
    margin: 0 0 40px;
}

.Question__item textarea {
    width: 100%;
    height: 100px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 12px;
    background-color: #eee;
    font-size: 16px;
    resize: none;
}

.Question__item textarea:focus {
    /* border: 2px solid gray; */
    outline: none !important;
    border-color: gray;
}


.Question__container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    cursor: pointer;
    line-height: 25px;
    user-select: none;
    height: 25px;
}

.Question__container input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.Question__checkmark_checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

.Question__container:hover input[type="checkbox"]~.Question__checkmark_checkbox {
    background-color: #ccc;
}

.Question__container input[type="checkbox"]:checked~.Question__checkmark_checkbox {
    background-color: gray;
}

.Question__checkmark_checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

.Question__container input[type="checkbox"]:checked~.Question__checkmark_checkbox:after {
    display: block;
}

.Question__container .Question__checkmark_checkbox:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.Question__container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.Question__checkmark_radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.Question__container:hover input[type="radio"]~.Question__checkmark_radio {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.Question__container input[type="radio"]:checked~.Question__checkmark_radio {
    background-color: gray;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.Question__checkmark_radio:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.Question__container input[type="radio"]:checked~.Question__checkmark_radio:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.Question__container .Question__checkmark_radio:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

@media (max-width: 768px) {
    .Question__item {
        margin: 0 20px 40px;
    }
}