.Welcome {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
}

.Welcome__text {
    margin: 0 20px 40px;
}

.Welcome__subtitle {
    font-size: 32px;
}