.Gallery {
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  scrollbar-width: none;
  cursor: grab;
}

.Gallery::-webkit-scrollbar {
  display: none;
}

.Gallery__slide {
  margin-right: 20px;
}

.Gallery__slide:first-child {
  margin-left: 20px;
}

.Gallery img {
  width: 300px;
  height: 400px;
  object-fit: cover;
  border-radius: 12px;
}