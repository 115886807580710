.Container {
  max-width: 800px;
  height: 100%;
  margin: auto;
  overflow: hidden;
  /* font-family: Vetrino; */
  user-select: none;
}

.Title {
  font-family: Annabelle;
  font-size: 48px;
  text-align: center;
  margin-bottom: 40px;
}